<template>
	<div class="mall-category">
		<mallHeader />
		<div class="product-category container">
			<ul class="breadcrumb">
				<li>
					<a href="#" @click="$router.push('/ori-mall')"
						><i class="el-icon-s-home"></i
					></a>
				</li>
				<li><i class="el-icon-arrow-right"></i></li>
				<li><a href="#">平板电脑、手机</a></li>
			</ul>
			<el-row>
				<el-col :span="4"
					><div class="grid-content bg-purple">
						<tabLeft /></div
				></el-col>
				<el-col :span="20"
					><div class="grid-content bg-purple-light">
						<h3 class="title-category ">配件</h3>
						<img src="@/assets/images/img-cate.jpg" alt="" />
						<div class="view-mode">
							<el-tooltip
								class="item"
								effect="dark"
								content="grid"
								placement="top"
							>
								<span
									class="el-icon-s-grid btn"
									:class="{ activeMode: gridShow }"
									@click.stop="grid()"
									ref="gridMode"
								></span>
							</el-tooltip>
							<el-tooltip
								class="item"
								effect="dark"
								content="list"
								placement="top"
							>
								<span
									class="el-icon-s-unfold btn"
									:class="{ activeMode: listShow }"
									@click.stop="list()"
									ref="listMode"
								></span>
							</el-tooltip>
							<div class="grid-mode" v-if="gridShow">
								<div class="product-items">
									<div class="product-item2" v-for="item in 15" :key="item">
										<a href="#"
											><img src="@/assets/images/h1.jpg" alt=""/>
											<img src="@/assets/images/h2.jpg" alt="" class="active"
										/></a>
										<div class="star">
											<ul>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/灰星星.svg" alt="" />
												</li>
											</ul>
										</div>
										<h4>
											<a
												href="product.html"
												title="Pastrami bacon"
												target="_self"
												>时尚切换</a
											>
										</h4>
										<div class="money">
											<span class="now-money">$55.00 </span>
											<span class="old-money">$76.00 </span>
										</div>
										<span class="lable">-9%</span>
										<!-- <span class="eye"
											><img src="@/assets/images/icon/眼睛.svg" alt=""
										/></span> -->
										<span class="refresh el-icon-refresh"></span>
										<span class="love el-icon-star-off"></span>
										<span class="addToCart">添加购物车</span>
									</div>
								</div>
								<div class="mode-bottom">
									<span>显示15页中的1至15页(1页)</span>
								</div>
							</div>
							<div class="list-mode" v-if="listShow">
								<div class="list-item" v-for="item in 15" :key="item">
									<a href="#"><img src="@/assets/images/e10.jpg" alt=""/></a>
									<div class="item-right">
										<div class="star">
											<ul>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/灰星星.svg" alt="" />
												</li>
											</ul>
										</div>
										<h4><a href="#">手表</a></h4>
										<div class="item-money">$80.00</div>
										<p>
											Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
											sed diam nonumy eirmod tempor invidunt ut labore et dolore
											magna aliquyam erat, sed diam voluptua. At vero eos et
											accusam et justo duo dolores et ea rebum. Stet clita kasd
											gubergren, no sea takimata sanctus est .
										</p>
										<span
											class="item-btn-first item-btn el-icon-shopping-bag-1"
										></span>
										<span class="item-btn el-icon-star-off"></span>
										<span class="item-btn el-icon-refresh"></span>
										<span class="item-btn el-icon-view"></span>
									</div>
								</div>
								<div class="mode-bottom">
									<span>显示15页中的1至15页(1页)</span>
								</div>
							</div>
							<div class="mode-count">
								<span class="sort-by">排序:</span>
								<el-autocomplete
									class="inline-input"
									v-model="state"
									:fetch-suggestions="querySearch"
								></el-autocomplete>
								<span class="sort-by">显示:</span>
								<el-select v-model="value1">
									<el-option
										v-for="item in options"
										:key="item.value"
										:label="item.label"
										:value="item.value"
									>
									</el-option>
								</el-select>
							</div>
						</div></div
				></el-col>
			</el-row>
		</div>
		<!-- <iframe src="http://localhost:8080/#/category" frameborder="1" width="1000px" marginheight="100px" height="500px"></iframe> -->
	</div>
</template>

<script>
import mallHeader from './header.vue'
import tabLeft from './tabLeft.vue'
export default {
	components: {
		mallHeader,
		tabLeft,
	},
	data() {
		return {
			activeName: 'first',
			gridShow: true,
			listShow: false,
			restaurants: [
				{
					value: '三全鲜食',
				},
				{
					value: '三全鲜食',
				},
				{
					value: '三全鲜食',
				},
				{
					value: '三全鲜食',
				},
			],
			options: [
				{
					value: '选项1',
					label: '15',
				},
				{
					value: '选项2',
					label: '25',
				},
				{
					value: '选项3',
					label: '50',
				},
				{
					value: '选项4',
					label: '75',
				},
				{
					value: '选项5',
					label: '100',
				},
			],
			value1: 15,
			state: '',
		}
	},
	created() {
		this.grid()
	},
	methods: {
		grid() {
			this.gridShow = true
			this.listShow = false
		},
		list() {
			this.gridShow = false
			this.listShow = true
		},
		querySearch(queryString, cb) {
			var restaurants = this.restaurants
			var results = queryString
				? restaurants.filter(this.createFilter(queryString))
				: restaurants
			// 调用 callback 返回建议列表的数据
			cb(results)
		},
		createFilter(queryString) {
			return (restaurant) => {
				return (
					restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
					0
				)
			}
		},
	},
}
</script>

<style scoped lang="scss">
.mall-category {
  font-family: fangsong;
	li {
		list-style: none;
	}
	.container {
		width: 1200px;
		margin: 0 auto;
	}
	.product-category {
		.breadcrumb {
			margin: 23px 0;
			background-color: transparent;
			padding: 0;
			display: inline-block;
			width: 100%;
			line-height: 100%;
			li {
				display: inline-block;
				padding-left: 8px;
				margin-right: 7px;
				position: relative;
				a {
					display: block;
					padding: 0;
					color: #999;
					text-decoration: none;
					font-size: 14px;
				}
				a:hover {
					color: #005ea6;
				}
				i {
					font-size: 14px;
				}
			}
		}
		.title-category {
			font-size: 18px;
			text-transform: uppercase;
			color: #222;
			width: 980px;
			border-bottom: 2px solid #eee;
			display: inline-block;
			position: relative;
			margin-top: 0;
			padding-bottom: 10px;
			margin-left: 20px;
		}
		.title-category::after {
			position: absolute;
			content: '';
			width: 110px;
			height: 2px;
			background-color: #005ea6;
			bottom: -2px;
			left: 0;
		}
		img {
			padding-left: 20px;
			width: 100%;
		}
		.view-mode {
			margin-top: 10px;
			position: relative;
			margin-left: 20px;
			.btn {
				width: 33px;
				height: 33px;
				background-color: #222;
				color: #fff;
				font-size: 18px;
				text-align: center;
				line-height: 33px;
				margin-right: 5px;
				border-radius: 5px;
			}
			.btn:hover {
				background-color: #005ea6;
			}
			.activeMode {
				background-color: #005ea6;
			}
			.grid-mode {
				margin-top: 30px;
				border-bottom: 1px solid #ebebeb;
				.product-items {
					// display: flex;
					margin-bottom: 40px;
					position: relative;
					.product-item2:hover .active {
						opacity: 0;
						transition: all 0.5s;
					}
					.product-item2:hover .eye {
						opacity: 1;
						animation: change2 0.5s;
					}
					.product-item2:hover .love {
						opacity: 1;
						animation: animLoadedHeader2 0.5s;
					}
					.product-item2:hover .refresh {
						opacity: 1;
						animation: animLoadedHeader1 0.5s;
					}
					.product-item2:hover .addToCart {
						opacity: 1;
						animation: animLoadedHeader3 0.5s;
					}
					.product-item2:hover .star {
						opacity: 0;
					}
					.product-item2:hover h4 {
						opacity: 0;
					}
					.product-item2 {
						position: relative;
						width: 196px;
						text-align: center;
						display: inline-block;
						a {
							img {
								position: relative;
								width: 196px;
								height: 196px;
								padding: 0;
							}
							.active {
								// opacity: 0;
								position: absolute;
								top: 0;
								right: 0;
							}
						}
						.star {
							ul {
								display: flex;
								padding-left: 70px;
								margin-bottom: 5px;
								li {
									list-style: none;
									img {
										width: 12px;
										height: 12px;
									}
								}
							}
						}
						h4 {
							font-size: 13px;
							color: #333;
						}
						.money {
							margin-bottom: 5px;
							.now-money {
								color: #005ea6;
								font-size: 16px;
								font-weight: 600;
							}
							.old-money {
								text-decoration: line-through;
								color: #aaa;
							}
						}
						.top-right {
							flex: 1;
							// background-color: #005ea6;
							display: flex;
							// justify-content: center;
							margin-left: 88px;
							align-items: center;
							.dot1 {
								width: 30px;
								height: 8px;
								border-radius: 4px;
								background-color: #005ea6;
								margin-right: 5px;
							}
							.dot2 {
								width: 8px;
								height: 8px;
								margin: 0 5px;
								background-color: #222;
								border-radius: 8px;
							}
						}
						.lable {
							width: 38px;
							height: 38px;
							border-radius: 38px;
							background-color: #ffd839;
							text-align: center;
							line-height: 38px;
							position: absolute;
							top: 0;
							right: 0;
						}
						.eye {
							width: 30px;
							height: 30px;
							background-color: #005ea6;
							position: absolute;
							top: 115px;
							right: 115px;
							text-align: center;
							line-height: 30px;
							border-radius: 30px;
							transform-origin: center;
							opacity: 0;
							cursor: pointer;
						}
						.eye:hover {
							background-color: #ff5e00;
						}
						.refresh {
							width: 30px;
							height: 30px;
							position: absolute;
							border: 2px solid #005ea6;
							top: 210px;
							right: 15px;
							text-align: center;
							border-radius: 30px;
							line-height: 25px;
							color: #005ea6;
							opacity: 0;
						}
						.refresh:hover {
							background-color: #005ea6;
							color: #fff;
						}
						.love {
							width: 30px;
							height: 30px;
							position: absolute;
							border: 2px solid #005ea6;
							top: 210px;
							right: 50px;
							text-align: center;
							line-height: 25px;
							border-radius: 30px;
							color: #005ea6;
							opacity: 0;
						}
						.love:hover {
							background-color: #005ea6;
							color: #fff;
						}
						.addToCart {
							height: 30px;
							width: 100px;
							position: absolute;
							top: 210px;
							right: 85px;
							text-align: center;
							background-color: #005ea6;
							color: #fff;
							line-height: 30px;
							border-radius: 15px;
							opacity: 0;
						}
						.addToCart:hover {
							background-color: #ff5e00;
						}
					}
					@keyframes change2 {
						from {
							transform: scale(0.5);
							opacity: 0;
						}
						to {
							transform: scale(1);
							opacity: 1;
						}
					}
					@keyframes animLoadedHeader1 {
						to {
							opacity: 1;
							transform: translate3d(0, 0px, 0);
						}
						from {
							opacity: 0;
							transform: translate3d(0, -40px, 0);
						}
					}
					@keyframes animLoadedHeader2 {
						to {
							opacity: 1;
							transform: translate3d(0, 0px, 0);
						}
						from {
							opacity: 0;
							transform: translate3d(0, -45px, 0);
						}
					}
					@keyframes animLoadedHeader3 {
						to {
							opacity: 1;
							transform: translate3d(0, 0px, 0);
						}
						from {
							opacity: 0;
							transform: translate3d(0, -50px, 0);
						}
					}
				}
				.mode-bottom {
					float: right;
					font-size: 14px;
					color: #666;
					padding: 30px 15px;
				}
			}
			.list-mode {
				margin-top: 30px;
				border-bottom: 1px solid #d7d7d7;
				.list-item {
					// background-color: yellow;
					display: flex;
					height: 270px;
					margin-bottom: 30px;
					img {
						border: 1px solid #d7d7d7;
					}
					.item-right {
						// background-color: yellow;
						height: 270px;
						width: 100%;
						padding-left: 20px;
						.star {
							ul {
								display: flex;
								padding-left: 0;
								margin-bottom: 5px;
								li {
									list-style: none;
									img {
										border: none;
										width: 12px;
										height: 12px;
									}
								}
							}
						}
						h4 {
							font-size: 16px;
							margin: 20px 0;
						}
						.item-money {
							color: #005ea6;
							font-size: 16px;
							font-weight: 600;
							margin-bottom: 20px;
						}
						p {
							font-size: 13px;
							color: #666;
						}
						.item-btn {
							width: 48px;
							height: 39px;
							text-align: center;
							line-height: 39px;
							border: 1px solid #d7d7d7;
							margin-right: 5px;
							border-radius: 5px;
						}
						.item-btn:hover {
							background-color: #005ea6;
							color: #fff;
						}
						.item-btn-first {
							background-color: #005ea6;
							color: #fff;
						}
						.item-btn-first:hover {
							background-color: #444;
						}
					}
				}
				.mode-bottom {
					float: right;
					font-size: 14px;
					color: #666;
					padding: 30px 15px;
				}
			}
			.mode-count {
				width: 410px;
				height: 33px;
				// background-color: yellow;
				position: absolute;
				top: 0;
				right: 0;
				display: flex;
				.sort-by {
					font-size: 14px;
					color: #666;
					line-height: 33px;
				}
				::v-deep {
					.el-input__inner {
						height: 33px;
						width: 150px;
						margin-right: 10px;
					}
					.el-input__inner:focus {
						outline: none;
						border-color: #409eff;
						box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
							0 0 8px rgb(102 175 233 / 60%);
					}
					.el-input__suffix {
						display: none;
					}
				}
			}
		}
	}
}
</style>
